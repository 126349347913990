<template>
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-12 mb-2">
				<router-link to="/">Home</router-link>
			</div>
			<div class="col-12">
				<h2>Shiny Locked Pokémon</h2>
				<div>
					This is a list of shiny locked Pokémon in each game.
					Some of these shiny Pokémon may be obtained via event distributions or other means.
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-sm-6 col-md mb-2 mb-md-0" v-for="game in shinyLocks" :key="game.id">
				<div><strong><u>{{ game.game }}</u></strong></div>
				<div class="mb-1 mb-md-0" v-for="pokemon in game.data" :key="`${game.id}-${pokemon}`">
					{{ pokemon }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import shinyLocks from '../pkmn-data/shiny-lock.json';
export default {
	data() {
		return { shinyLocks };
	},
}
</script>